import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { Map } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { Extent } from 'ol/extent';
import { LayerH60B } from './layers/layer-eumetsat-h60b';
import { LayerIR108 } from './layers/layer-eumetsat-ir108';
import { LayerRGBAirMass } from './layers/layer-eumetsat-rgb_airmass';
import { LayerRGBConvection } from './layers/layer-eumetsat-rgb_convection';
import { LayerBZL } from './layers/layer-bzl';
import { LayerCZL } from './layers/layer-czl';
import { LayerGPL } from './layers/layer-gpl';
import { LayerRRL } from './layers/layer-rrl';
import { LayerRNL } from './layers/layer-rnl';
import { LayerYML } from './layers/layer-yml';
import { MapLayer } from './layers/map-layer';
import { AgroradMapLayer } from './layers/custom-map-layer'

const ITALY_BOUNDS_DICT = {
    north: 47.1153931748,
    south: 36.619987291,
    east: 18.4802470232,
    west: 6.7499552751,
};

const ITALY_CENTER = [
    (ITALY_BOUNDS_DICT.east + ITALY_BOUNDS_DICT.west) / 2,
    (ITALY_BOUNDS_DICT.north + ITALY_BOUNDS_DICT.south) / 2,
];

const MAPBOX_STYLE: string = 'netfarm/cl5qwosu0000g15pmex2tx2hg';
const MAPBOX_TOKEN: string = 'pk.eyJ1IjoibmV0ZmFybSIsImEiOiJja2Eyb3VpOWcwNWF6M2Rsa3dxdzNucGszIn0.bzaVMGs6jdka2A7Pl90spQ';
//pk.eyJ1IjoibmV0ZmFybSIsImEiOiJjbDVxd3Qzdm4yMXRnM2ptZ3Vtc3VvdzR0In0.YUDH0MvhOVTLplBxdtsPJg

@Component({
    selector: 'app-geo-map',
    templateUrl: './geo-map.component.html',
    styleUrls: ['./geo-map.component.scss']
})
export class GeoMapComponent implements OnInit,AfterViewInit {

    center: Coordinate = [11.2059486, 43.7799528]; //ITALY_CENTER;
    //extent: Extent = [-343349.5784832514, 4069839.252779271, 3151962.8509412883, 6212522.029669331]; // italy
    extent: Extent = null; //[1072006.7541592815, 5275066.621960149, 1431942.5490512094, 5570797.653438923]; // tuscany
    zoom: number = 11;
    minZoom: number = 5;
    maxZoom: number = 15;
    mapBoxStyle: string = MAPBOX_STYLE;
    mapBoxToken: string = MAPBOX_TOKEN;

    map: Map = null;
    layers: AgroradMapLayer[];
    constructor(private zone: NgZone) {
    }
    ngAfterViewInit(): void {
    }

    ngOnInit(): void {
        this.layers = [
            new LayerBZL(),
            new LayerCZL(),
            new LayerGPL(),
            new LayerRRL(),
            new LayerYML(),
            new LayerRNL(),
            new LayerH60B(),
            new LayerIR108(),
            new LayerRGBConvection(),
        ];
    }

    mapReady(map: Map): void {
        this.map = map;
        setTimeout(() => {
            this.setTime(new Date().getTime());
            this.zone.runOutsideAngular(() => {
                this.map.updateSize();
                for (let nfLayer of this.layers) {
                    nfLayer.update();
                }
            });
        }, 0);
    }

    addTime(hours: number): void {
        this.zone.runOutsideAngular(() => {
            this.map.updateSize();
            for (let nfLayer of this.layers) {
                nfLayer.addTime(hours);
                nfLayer.update();
            }
        });
        setTimeout(() => this.map.updateSize(), 0);
    }


    setTime(timestamp: number): void {
        this.zone.runOutsideAngular(() => {
            this.map.updateSize();
            for (let nfLayer of this.layers) {
                nfLayer.setTime(timestamp);
                nfLayer.update();
            }
        });
        setTimeout(() => this.map.updateSize(), 0);
    }


}
