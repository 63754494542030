import { Feature, MapBrowserEvent } from 'ol';
import BaseLayer from 'ol/layer/Base';
import { NfLayer } from 'src/app/netfarm/components/geo-map/nf-layer';

export class MapLayer extends NfLayer {

    override mapProjection: string = 'EPSG:3857';
    override dataProjection: string = 'EPSG:4326';

    minZoom: number = 5;
    maxZoom: number = 15;

    override selectable: boolean = false;

    override is_selectable_feature(layer: BaseLayer, feature: Feature) {
        return this.selectable;
    }

    constructor() {
        super();
    }

    update(): void {

    }

    getLayers(): BaseLayer[] {
        return [this.layer];
    }

    onSelect(layer: BaseLayer, feature: Feature): void {

    }

    onHover(event: MapBrowserEvent<any>, layer: BaseLayer, feature: Feature): void {

    }

    onZoomChanged(zoom: number): void {

    }

}
