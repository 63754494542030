import { Tile } from 'ol/layer';
import { TileWMS } from 'ol/source';
import { AgroradMapLayer } from './custom-map-layer'

export class LayerCZL extends AgroradMapLayer {

    constructor() {
        super();
        this.id = 'czl';
        this.caption = 'Vertical Max Intensity';
        this.zIndex = 10000;
        this.params = {
            FORMAT: 'image/png',
            VERSION: '1.1.0',
            tiled: true,
            WIDTH: 1024,
            HEIGHT: 1024,
            STYLES: '',
            LAYERS: 'AGRORAD:CZL',
            exceptions: 'application/vnd.ogc.se_inimage',
            tilesOrigin: 1613444.69 + ',' + 4788429.21,
            TIME: new Date().toISOString()
        };
        this.layer = new Tile({
            visible: true,
            source: new TileWMS({
                url: 'https://agrorad.netfarm.it/geoserver/AGRORAD/wms',
                params: this.params
            }),
            zIndex: this.zIndex,
            // opacity: 0.8
        });
        this.visible=true;
    }
}
