<div class="slider-row row w-100 mx-0 p-3 pt-4">
  <input
    type="range"
    min="1"
    max="100"
    class="form-control-range slider"
    id="myRange"
    [(ngModel)]="slider"
    (change)="setTime($event)"
  />
  <label class="slider-label" [textContent]="data" (click)="open(content)"> qui ci va la data </label>
</div>
<div class="row h-100 w-100 m-0 p-0">
  <app-geo-map #map class="h-100 w-100 m-0 p-0"></app-geo-map>
  <div class="w-20 control-panel">
    <div *ngFor="let layer of map.layers; index as idx">
      <button
        class="btn btn-secondary w-100 mt-2 text-left"
        (click)="doChangeVisibility(idx)"
      >
        <fa-icon [icon]="getVisibilityIcon(layer.visible)"></fa-icon>
        {{ layer.caption || layer.id }}
      </button>
    </div>
  </div>
</div>
<ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Modifica data</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<form>
			<div class="mb-3 row">
				<div class="col-6">
					<label for="centerDate" class="col-12">Data</label>
					<div class="input-group mt-3">
						<input
							id="centerDate"
							class="form-control"
							placeholder="dd/mm/yyyy"
							name="dp"
							ngbDatepicker
							#dp="ngbDatepicker"
							[(ngModel)]="centerdatengb"
						/>
						<button class="btn btn-outline-secondary bi bi-calendar3" (click)="dp.toggle()" type="button"></button>
					</div>
				</div>
				<ngb-timepicker name="tp" class="col-6"
					[minuteStep]="5"
					[(ngModel)]="centertimengb"></ngb-timepicker>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-dark" (click)="modal.close('OK')">OK</button>
	</div>
</ng-template>