<nav class="navbar navbar-top navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#"><img src="/assets/agrorad.png"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
                <a class="nav-link" href="/">Mappa</a>
            </li>
            <li class="nav-item active">
                <a class="nav-link" href="/riot" target="_blank">Radar monitoring console</a>
            </li>
            <li class="nav-item active">
                <a class="nav-link" href="/riot/admin" target="_blank">Backend</a>
            </li>
        </ul>
    </div>
</nav>
<div class="agrorad-main-content w-100">
    <router-outlet></router-outlet>
</div>
<nav class="navbar navbar-bottom navbar-light bg-light justify-content-center">
    <a class="navbar-brand" href="https://www.eldesradar.com/" target="_blank">
        <img src="/assets/logo Eldes.png">
    </a>
    <a class="navbar-brand" href="https://www.ibe.cnr.it/" target="_blank">
        <img src="/assets/logo IBE.png">
    </a>
    <a class="navbar-brand" href="https://www.netfarm.it" target="_blank">
        <img src="/assets/logo Netfarm.png">
    </a>
    <a class="navbar-brand" href="https://www.pasquali-microwavesystems.com/" target="_blank">
        <img src="/assets/logo Pasquali.jpg">
    </a>
    <a class="navbar-brand" href="https://www.unifi.it/" target="_blank">
        <img src="/assets/logo UniFI.png">
    </a>
    <a class="navbar-brand" href="https://www.regione.toscana.it/porcreo-fesr-2014-2020" target="_blank">
        <img src="/assets/logo por creo fesr 2014-2020.jpg">
    </a>
</nav>
