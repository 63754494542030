import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
//import { CoordinatesModule } from 'angular-coordinates';
import { NfGeoMapComponent } from './components/geo-map/nf-geo-map/nf-geo-map.component';


@NgModule({
    declarations: [
        NfGeoMapComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        FormlyModule.forRoot({ extras: { lazyRender: true } }),
        FormlyBootstrapModule,
//        CoordinatesModule,
    ],
    exports: [
        NfGeoMapComponent,
        FormsModule
    ]
})
export class NetfarmModule { }
