import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GeoMapComponent } from './components/geo-map/geo-map.component';
import { faEye, faEyeSlash, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, ModalDismissReasons, NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';

@Component({
  selector: 'app-agrorad',
  templateUrl: './agrorad.component.html',
  styleUrls: ['./agrorad.component.scss']
})
export class AgroradComponent implements OnInit {
  @ViewChild('map') map: GeoMapComponent;

  data: string = '-';
  slider: number = 80;
  settimescheduler: any = null;
  //centerdate:Date = new Date('2022-09-15T05:50:00');
  centerdate:Date = new Date(Math.floor((new Date()).getTime()/(5 * 60 * 1000)) * (5 * 60 * 1000));
  centerdatengb:NgbDateStruct = this.dateToNgbDateStruct(this.centerdate);
  centertimengb:NgbTimeStruct = this.dateToNgbTimeStruct(this.centerdate);

  faEye = faEye;
  faEyeSlash = faEyeSlash;

  constructor(private modalService: NgbModal) {
  }

  ngOnInit() {
    setTimeout(() => {
      let timestamp: number = Math.floor(this.centerdate.getTime()/(5 * 60 * 1000) - (80 - this.slider)) * (5 * 60 * 1000);
      this.data = moment(timestamp).format("DD/MM/YYYY, hh:mm") + ' UTC';
      this.map.setTime(timestamp);
    }, 1000);
  }

  setTime(e) {
    if (this.settimescheduler != null) {
      clearTimeout(this.settimescheduler);
    }
    this.settimescheduler = setTimeout(() => {
      let timestamp: number = Math.floor(this.centerdate.getTime()/(5 * 60 * 1000) - (80 - this.slider)) * (5 * 60 * 1000);
      this.data = moment(timestamp).format("DD/MM/YYYY, hh:mm") + ' UTC';
      this.map.setTime(timestamp);
    }, 250);
  }

  getVisibilityIcon(isVisible): IconDefinition {
    let iconDef: IconDefinition;
    iconDef = this.faEyeSlash;
    if (isVisible) {
      iconDef = this.faEye;
    }
    return iconDef;
  }
  doChangeVisibility(idx){
    this.map.layers[idx].visible = !this.map.layers[idx].visible;
  }

  open(content) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.centerdate=this.ngbDateStructToDate(this.centerdatengb);
        this.centerdate.setHours(this.centertimengb.hour);
        this.centerdate.setMinutes(this.centertimengb.minute);
        this.slider=80;
        this.setTime('');
			},
			(reason) => {
				console.log(`Dismissed ${this.getDismissReason(reason)}`);
			},
		);
	}

  private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

  /**
   * Trasforma NgbDateStruct in data
   * @param structD
   * @returns
   */
  ngbDateStructToDate(structD: NgbDateStruct): Date {
    if (!structD) {
      return null;
    }
    return new Date(
      structD.year,
      structD.month - 1,
      structD.day,
    );
  }


  /**
   * Trasforma data in NgbDateStruct
   * @param date
   * @returns
   */
  dateToNgbDateStruct(date: Date | string): NgbDateStruct {
    if (!date) {
      return null;
    }
    if (typeof date === 'string') {
      date = moment(date).toDate();
    }
    let d = date as Date;
    let res = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
    console.log(res);
    return res;
  }

  dateToNgbTimeStruct(date: Date | string): NgbTimeStruct{
    if (!date) {
      return null;
    }
    if (typeof date === 'string') {
      date = moment(date).toDate();
    }
    let d = date as Date;
    let res = {
      hour: d.getHours(),
      minute: d.getMinutes(),
      second:0
    };
    console.log(res);
    return res;
  }
}
