import { Tile } from 'ol/layer';
import { TileWMS } from 'ol/source';
import { AgroradMapLayer } from './custom-map-layer'

export class LayerRGBConvection extends AgroradMapLayer {

    constructor() {
        super();
        this.id = 'rgb_convection';
        this.caption = "Convection RGB - MSG - 0 degree";
        this.zIndex = 10000;
        this.params = {
            SERVICE: "WMS",
            REQUEST:"GetMap",
            FORMAT:'image/png',
            TRANSPARENT:true,
            LAYERS:"msg_fes:rgb_convection",
            VERSION: '1.3.0',
            tiled: true,
            WIDTH: 1024,
            HEIGHT: 1024,
            STYLES: '',
            TIME: new Date().toISOString()
        };
        this.layer = new Tile({
            visible: false,
            source: new TileWMS({
                url: 'https://agrorad.netfarm.it/eumetsat/geoserver/ows',
                //url: 'https://view.eumetsat.int/geoserver/ows',
                params: this.params
            }),
            zIndex: this.zIndex,
            opacity: 0.25
        });
        this.visible=false;
    }
}
