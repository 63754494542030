import { Feature, MapBrowserEvent } from 'ol';
import BaseLayer from 'ol/layer/Base';


export abstract class NfLayer {

    id: string;
    layer: BaseLayer;

    mapProjection: string;
    dataProjection: string;


    is_selectable_feature(layer: BaseLayer, feature: Feature) {
        return this.selectable;
    }

    private _visible: boolean = true;
    public get visible(): boolean {
        return this._visible;
    }
    public set visible(value: boolean) {
        this.layer.setVisible(value);
        this._visible = value;
    }

    hoverable: boolean = false;

    selectable: boolean = true;
    selectedFeatures: Feature[] = [];

    constructor() {}

    abstract update(): void;

    abstract getLayers(): BaseLayer[];

    abstract onSelect(layer: BaseLayer, feature: Feature): void;

    abstract onHover(event: MapBrowserEvent<any>, layer: BaseLayer, feature: Feature): void;

    abstract onZoomChanged(zoom: number): void;

}
