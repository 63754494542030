import { MapLayer } from './map-layer';
import { Tile } from 'ol/layer';
import { TileWMS } from 'ol/source';

export class AgroradMapLayer extends MapLayer {

    //id: string;
    caption: string;
    //selectable: boolean = false;
    zIndex: number = 10000;
    params: any ;
    override layer: Tile<any>;

    constructor() {
        super();
    }

    clear(): void {
        this.layer.getSource().clear();
    }

    addTime(hours: number): void {
        const t: Date = new Date(this.params.TIME);
        t.setTime(t.getTime() + (hours * 60 * 60 * 1000));
        this.params.TIME = t.toISOString();
        (this.layer.getSource() as TileWMS).updateParams(this.params);
    }

    setTime(timestamp: number): void {
        const t: Date = new Date(timestamp);
        this.params.TIME = t.toISOString();
        console.log(this.params.TIME);
        (this.layer.getSource() as TileWMS).updateParams(this.params);
    }
}
