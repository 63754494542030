import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgroradComponent } from './agrorad.component';
import { AgroradRoutingModule } from './agrorad-routing.module';
import { NetfarmModule } from '../netfarm/netfarm.module';
import { GeoMapComponent } from './components/geo-map/geo-map.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
    imports: [
        CommonModule,
        AgroradRoutingModule,
        NetfarmModule,
        FontAwesomeModule,
        NgbModule
    ],
    declarations: [
        AgroradComponent,
        GeoMapComponent
    ]
})
export class AgroradModule { }
