import { AfterViewInit, Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { NfLayer } from '../nf-layer';
import { Feature, Map, MapBrowserEvent, View } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { createEmpty, extend, Extent } from 'ol/extent';
import * as olProj from 'ol/proj';
import olms from 'ol-mapbox-style';
import { Layer, Tile } from 'ol/layer';
import MapboxVector from 'ol/layer/MapboxVector';
import BaseLayer from 'ol/layer/Base';
import TileLayer from 'ol/layer/Tile';
import { TileImage, TileDebug } from 'ol/source';
import LayerGroup from 'ol/layer/Group';
import { Pixel } from 'ol/pixel';
import { getArea, getLength } from 'ol/sphere';
import { Draw, Modify } from 'ol/interaction';
import { ScaleLine, FullScreen, Zoom, ZoomSlider, defaults as defaultControls } from 'ol/control';
import { Fill, RegularShape, Stroke, Style, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import VectorSource from 'ol/source/Vector';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
//import GeometryType from 'ol/geom/GeometryType';
import Geometry from 'ol/geom/Geometry';

enum SelectionMode {
    None = 0,
    Last = 1,
    All = 2
}


@Component({
    selector: 'app-nf-geo-map',
    templateUrl: './nf-geo-map.component.html',
    styleUrls: ['./nf-geo-map.component.scss']
})
export class NfGeoMapComponent implements OnInit, AfterViewInit {

    @Input() mapProjection = 'EPSG:3857';
    @Input() center: Coordinate = null;
    @Input() extent: Extent = null;
    @Input() zoom: number = null;
    @Input() minZoom: number = null;
    @Input() maxZoom: number = null;

    @Input() mapboxStyle: string = null;
    @Input() mapBoxTopoStyle: string = null;
    @Input() mapboxKey: string = null;
    mapboxLayer: MapboxVector = null;
    mapboxLayers: BaseLayer[] = [];

    @Input() satellite: boolean = false;
    satelliteLayer: BaseLayer = null;

    @Input() tileDebug: boolean = false;
    tileDebugLayer: BaseLayer = null;

    @Input() layers: NfLayer[] = [];
    @Input() selectionMode: SelectionMode = SelectionMode.None;
    @Input() zoomDuration: number = 500;

    @Output() mapReady: EventEmitter<Map> = new EventEmitter();
    @Output() selectionChanged: EventEmitter<void> = new EventEmitter();
    @Output() select: EventEmitter<any> = new EventEmitter();
    @Output() deselect: EventEmitter<any> = new EventEmitter();
    @Output() hoverEnd: EventEmitter<void> = new EventEmitter();

    map: Map;

    constructor(private zone: NgZone) {

    }

    /* Setup */
    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        if (!this.map) {
            this.zone.runOutsideAngular(() => this.initMap());
        }
        setTimeout(() => {
            //this.map.updateSize();
            this.mapReady.emit(this.map);
        });
    }

    setBaseLayer(layer: string) {
        if (layer == 'map') {
            this.setMapboxLayer(this.mapboxStyle, this.mapboxKey);
            return
        }

        if (layer == 'topo') {
            this.setMapboxLayer(this.mapBoxTopoStyle, this.mapboxKey);
            return
        }

        if (layer == 'satellite') {
            this.remove_base_layers()
            let satelliteLayer = new Tile({
                source: new TileImage({
                    url: 'https://mt1.google.com/vt/lyrs=s&hl=en&&x={x}&y={y}&z={z}'
                }),
                visible: true
            });
            this.map.addLayer(satelliteLayer);
            this.current_base_layers = [satelliteLayer]
        }
    }
    current_base_layers: BaseLayer[] = [];

    remove_base_layers() {
        this.current_base_layers.forEach(l => this.map.removeLayer(l));
        this.current_base_layers = [];
    }

    setMapboxLayer(mapboxStyle: string, mapboxKey) {
        this.remove_base_layers();

        const url: string = 'https://api.mapbox.com/styles/v1/' + mapboxStyle + '?access_token=' + this.mapboxKey;
        olms(this.map, url).then(() => {
            this.map.getLayers().forEach(layer => {
                if (layer.get('mapbox-source')) {
                    this.current_base_layers.push(layer);
                }
            });
        });

    }

    initMap(): void {
        // https://openlayers.org/en/latest/examples/scale-line.html

        // per stilare
        //https://openlayers.org/en/latest/examples/zoomslider.html
        let scaleControl = new ScaleLine({
            units: 'metric',
            bar: false,
            steps: 4,
            minWidth: 140,
        });

        // let m = document.getElementById('map');
        // console.log("MAP DIM", m.offsetWidth, m.offsetHeight);
        const viewParams: any = {};
        if (this.extent) {
            viewParams.extent = this.extent;
        }
        this.map = new Map({
            layers: [],
            target: 'map',
            controls: defaultControls().extend([scaleControl, new ZoomSlider(), new Zoom()]),
            view: new View(viewParams)
        });
        if (this.minZoom) {
            this.map.getView().setMinZoom(this.minZoom);
        }
        if (this.maxZoom) {
            this.map.getView().setMaxZoom(this.maxZoom);
        }
        if (this.zoom) {
            this.map.getView().setZoom(this.zoom);
        }
        if (this.center) {
            this.map.getView().setCenter(olProj.fromLonLat(this.center));
        }

        this.zone.runOutsideAngular(() => {
            this.setBaseLayer('map')

            if (this.tileDebug) {
                this.tileDebugLayer = new TileLayer({
                    source: new TileDebug(),
                    zIndex: 50000,
                    visible: false
                });
                this.map.addLayer(this.tileDebugLayer);
            }

            for (let nfLayer of this.layers) {
                this.map.addLayer(nfLayer.layer);
            }

            this.map.on('click', (event) => {
                this.zone.runOutsideAngular(() => {
                    this.selectionHandler(event);
                });
            });

            this.map.on('pointermove', (event) => {
                this.zone.runOutsideAngular(() => {
                    if (event.dragging) {
                        return;
                    }
                    const pixel: Pixel = this.map.getEventPixel(event.originalEvent);
                    let target: Feature = null;
                    this.map.forEachFeatureAtPixel(event.pixel, (feature: Feature, layer: Layer) => {
                        if (target) {
                            return;
                        }
                        const nfLayer: NfLayer = this.getMapLayer(layer);
                        if (nfLayer?.is_selectable_feature(layer, feature)) {
                            target = feature;

                        }
                    });

                    if (target) {
                        this.map.getTargetElement().style.cursor = 'pointer';
                    }
                    else {
                        this.map.getTargetElement().style.cursor = '';
                    }
                    this.hoverHandler(event);


                });
            });

            this.map.on('moveend', (event) => {
                const zoom: number = this.map.getView().getZoom();
                this.zone.runOutsideAngular(() => {
                    for (let nfLayer of this.layers) {
                        if (nfLayer.visible) {
                            nfLayer.onZoomChanged(zoom);
                        }
                    }
                });
            });
            this.map.addLayer(this.vector);
            //this.addInteraction();
        });
    }

    toggleTileDebugLayer(): void {
        this.tileDebugLayer.setVisible(!this.tileDebugLayer.getVisible());
    }

    /* Interaction */
    hovering: boolean = false;
    hoverHandler(event: MapBrowserEvent<any>): void {
        let hoveredFeature: Feature = null;
        this.map.forEachFeatureAtPixel(event.pixel, (feature: Feature, layer: Layer) => {
            let nfLayer: NfLayer = this.getMapLayer(layer);
            if (!nfLayer) {
                return;
            }
            hoveredFeature = feature;
            if (nfLayer.hoverable) {
                nfLayer.onHover(event, layer, feature);
            }
            this.hovering = true;
        }, {
            layerFilter: (l) => {
                const nfLayer: NfLayer = this.getMapLayer(l);
                return nfLayer != null && nfLayer.hoverable;
            }
        });
        if (this.hovering && !hoveredFeature) {
            this.hovering = false;
            this.hoverEnd.emit();
        }
    }

    selectionHandler(event: MapBrowserEvent<any>): void {
        let selected: boolean = false;
        let selectedLayer: NfLayer = null;
        let selectedFeature: Feature = null;
        this.map.forEachFeatureAtPixel(event.pixel, (feature: Feature, layer: Layer) => {
            let nfLayer: NfLayer = this.getMapLayer(layer);
            if (!nfLayer) {
                return;
            }
            if (!selected && nfLayer.selectable) {
                selectedLayer = nfLayer;
                selectedFeature = feature;
                selected = true;
                nfLayer.onSelect(layer, feature);
            }
        });
        if (!selectedLayer || !selectedFeature) {
            return;
        }

        // zoom
        if (this.selectionMode == SelectionMode.Last) {
            this.map.getView().fit(selectedFeature.getGeometry().getExtent(), { duration: this.zoomDuration });
        }
        else if (this.selectionMode == SelectionMode.All) {
            let extent: Extent = createEmpty();
            selectedLayer.selectedFeatures.forEach(f => extend(extent, f.getGeometry().getExtent()));
            this.map.getView().fit(extent, { duration: this.zoomDuration });
        }
    }

    /* Ruler */
    style: Style = new Style({
        fill: new Fill({
            color: 'rgba(0, 255, 0, 0.8)',
        }),
        stroke: new Stroke({
            color: 'rgba(0, 255, 0, 0.8)',
            lineDash: [10, 10],
            width: 2,
        }),
        image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
                color: 'rgba(0, 0, 0, 0.7)',
            }),
            fill: new Fill({
                color: 'rgba(0, 255, 0, 0.8)',
            }),
        }),
    });
    labelStyle: Style = new Style({
        text: new Text({
            font: '14px Calibri,sans-serif',
            fill: new Fill({
                color: 'rgba(255, 255, 255, 1)',
            }),
            backgroundFill: new Fill({
                color:  'rgba(42, 44, 59, 0.7)',
            }),
            padding: [3, 3, 3, 3],
            textBaseline: 'bottom',
            offsetY: -15,
        }),
        image: new RegularShape({
            radius: 8,
            points: 3,
            angle: Math.PI,
            displacement: [0, 10],
            fill: new Fill({
                color: 'rgba(42, 44, 59, 0.7)', // punta tooltip
            }),
        }),
    });
    tipStyle: Style = new Style({
        text: new Text({
            font: '12px Calibri,sans-serif',
            fill: new Fill({
                color: 'rgba(255, 255, 255, 1)',
            }),
            backgroundFill: new Fill({
                color: 'rgba(42, 44, 59, 0.4)',
            }),
            padding: [2, 2, 2, 2],
            textAlign: 'left',
            offsetX: 15,
        }),
    });
    modifyStyle: Style = new Style({
        image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
                color: 'rgba(42, 44, 59, 0.7)',
            }),
            fill: new Fill({
                color: 'rgba(42, 44, 59, 0.4)',
            }),
        }),
        text: new Text({
            text: 'Drag to modify',
            font: '12px Calibri,sans-serif',
            fill: new Fill({
                color: 'rgba(255, 255, 255, 1)',
            }),
            backgroundFill: new Fill({
                color: 'rgba(42, 44, 59, 0.7)',
            }),
            padding: [2, 2, 2, 2],
            textAlign: 'left',
            offsetX: 15,
        }),
    });
    segmentStyle: Style = new Style({
        text: new Text({
            font: '12px Calibri,sans-serif',
            fill: new Fill({
                color: 'rgba(255, 255, 255, 1)', // testo tooltip seg
            }),
            backgroundFill: new Fill({
                color: 'rgba(42, 44, 59, 0.4)',// sfondo
            }),
            padding: [2, 2, 2, 2],
            textBaseline: 'bottom',
            offsetY: -12,
        }),
        image: new RegularShape({
            radius: 6,
            points: 3,
            angle: Math.PI,
            displacement: [0, 8],
            fill: new Fill({
                color: 'rgba(42, 44, 59, 0.4)', // punta tooltip segmento
            }),
        }),
    });
    segmentStyles: Style[] = [this.segmentStyle];

    showSegmentLength: boolean = true;

    formatLength(line) {
        const length = getLength(line);
        let output;
        if (length > 100) {
            output = Math.round((length / 1000) * 100) / 100 + ' km';
        }
        else {
            output = Math.round(length * 100) / 100 + ' m';
        }
        return output;
    };

    formatArea(polygon) {
        const area = getArea(polygon);
        let output;
        if (area > 10000) {
            output = Math.round((area / 1000000) * 100) / 100 + ' km\xB2';
        }
        else {
            output = Math.round(area * 100) / 100 + ' m\xB2';
        }
        return output;
    };

    source: VectorSource = new VectorSource();
    modify: Modify = new Modify({ source: this.source, style: this.modifyStyle });
    tipPoint: Geometry;

    styleFunction(feature, segments, drawType?, tip?) {
        const styles = [this.style];
        const geometry = feature.getGeometry();
        const type = geometry.getType();
        let point, label, line;
        if (!drawType || drawType === type) {
            if (type === 'Polygon') {
                point = geometry.getInteriorPoint();
                label = this.formatArea(geometry);
                line = new LineString(geometry.getCoordinates()[0]);
            }
            else if (type === 'LineString') {
                point = new Point(geometry.getLastCoordinate());
                label = this.formatLength(geometry);
                line = geometry;
            }
        }
        if (segments && line) {
            let count = 0;
            line.forEachSegment((a, b) => {
                const segment = new LineString([a, b]);
                const label = this.formatLength(segment);
                if (this.segmentStyles.length - 1 < count) {
                    this.segmentStyles.push(this.segmentStyle.clone());
                }
                const segmentPoint = new Point(segment.getCoordinateAt(0.5));
                this.segmentStyles[count].setGeometry(segmentPoint);
                this.segmentStyles[count].getText().setText(label);
                styles.push(this.segmentStyles[count]);
                count++;
            });
        }
        if (label) {
            this.labelStyle.setGeometry(point);
            this.labelStyle.getText().setText(label);
            styles.push(this.labelStyle);
        }
        if (
            tip &&
            type === 'Point' &&
            !this.modify.getOverlay().getSource().getFeatures().length
        ) {
            this.tipPoint = geometry;
            this.tipStyle.getText().setText(tip);
            styles.push(this.tipStyle);
        }
        return styles;
    }

    vector: VectorLayer<VectorSource<Geometry>> = new VectorLayer({
        source: this.source,
        style: (feature) => {
            return this.styleFunction(feature, this.showSegmentLength);
        },
        zIndex: 99999
    });

    draw: Draw;

    addInteraction() {
        const drawType = 'LineString'; //GeometryType.LINE_STRING;// //this.typeSelect.value;
        const activeTip = 'Double click to Stop.';// +/* (drawType === 'Polygon' ? 'polygon' : 'line');*/
        const idleTip = 'Click to Start measuring';
        let tip = idleTip;
        this.draw = new Draw({
            source: this.source,
            type: drawType,
            style: (feature) => {
                return this.styleFunction(feature, this.showSegmentLength, drawType, tip);
            },
        });
        this.draw.on('drawstart', () => {
            // if (true/*this.clearPrevious.checked*/) {
            //     this.source.clear();
            // }
            this.modify.setActive(false);
            tip = activeTip;
        });
        this.draw.on('drawend', () => {
            this.modifyStyle.setGeometry(this.tipPoint);
            this.modify.setActive(true);
            this.map.once('pointermove', () => {
                this.modifyStyle.setGeometry(null);
            });
            tip = idleTip;
        });
        this.modify.setActive(true);
        this.map.addInteraction(this.draw);
    }

    rulerEnabled: boolean = false;
    toggleRuler(): void {
        this.rulerEnabled = !this.rulerEnabled;
        if (this.rulerEnabled) {
            this.addInteraction();
        }
        else {
            this.map.removeInteraction(this.draw);
            this.vector.getSource().clear();
        }
    }

    /* Utils */
    getMapLayer(layer: Layer): NfLayer {
        if (!layer) {
            return null;
        }
        for (let nfLayer of this.layers) {
            if (nfLayer.layer instanceof LayerGroup) {
                let found: NfLayer = null;
                (nfLayer.layer as LayerGroup).getLayers().forEach(item => {
                    if ((item as any).ol_uid === (layer as any).ol_uid) {
                        found = nfLayer;
                    }
                });
                if (found) {
                    return found;
                }
            }
            else if ((nfLayer.layer as any).ol_uid === (layer as any).ol_uid) {
                return nfLayer;
            }
        }
        return null;
    }

}
