import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AgroradComponent } from './agrorad.component';

const routes: Routes = [{
    path: '',
    component: AgroradComponent
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class AgroradRoutingModule { }